import { JsonObject } from 'type-fest';

export const datePicker: JsonObject = {
  whatHourAreYouAvailable: 'What hours are you available?',
  today: 'Today',
  weekDaysShort: {
    monday: 'Mo',
    tuesday: 'Tu',
    wednesday: 'We',
    thursday: 'Th',
    friday: 'Fr',
    saturday: 'Sa',
    sunday: 'Su',
  },
  scheduleMeetingModal: {
    title: 'When you’re available to meet with this candidate?',
    actions: {
      send: 'Send',
      confirm: 'Confirm',
    },
  },
  meetingHourSelect: {
    placeholder: {
      title: 'Select date and time',
      subtitle: 'You can choose multiple dates and timeslots that work for you',
    },
    addAnotherTime: 'Add another time',
  },
  successBox: {
    title: 'Thank you',
    subtitle: 'We will contact you as soon as we confirm the candidate availability',
    selectedTimeslots: 'Selected timeslots',
    close: 'Close',
  },
  errorMessage: 'Something went wrong, please try again later or contact administrator',
  availableMeetingOptions: {
    60: '1 hour meeting',
    90: '1.5 hour meeting',
    120: '2 hour meeting',
  },
};

export default datePicker;
