import { JsonObject } from 'type-fest';

export const applications: JsonObject = {
  applicationsAmount:
    '(1)[{{amount}} aplikacja];(2-4)[{{amount}} aplikacje];(0,5-21)[{{amount}} aplikacji];',
  tabs: {
    active: 'Aktywne',
    ended: 'Zakończone',
  },
  activeApplications: {
    title: 'Analiza aplikacji',
  },
  endedApplications: {
    title: 'Aplikacje zakończone',
  },
};
