import { ArrowIcon } from 'icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectisWebsiteMenuItem } from '../../../../configs/menu';

interface ConnectisWebsiteHeaderMenuItemProps extends ConnectisWebsiteMenuItem {
  menuName: string;
}

export const ConnectisWebsiteHeaderMenuItem: FC<ConnectisWebsiteHeaderMenuItemProps> = ({
  name,
  menuName,
  url,
  thumbnail,
  icon: Icon,
  menuOrder,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: `connectisWebsite:menus.${menuName}.items`,
  });

  const { label, description } = t(name, {
    returnObjects: true,
  }) as { label: string; description?: string };

  return (
    <li
      style={{
        order: menuOrder,
      }}
    >
      <a
        className="flex items-center lg:p-[.5rem_2.6rem_.5rem_.5rem] hover:bg-black/10 rounded-xl relative group gap-3"
        href={url}
      >
        {thumbnail && (
          <img
            alt=""
            className="h-[3.875rem] w-[3.875rem] object-cover rounded-lg"
            src={thumbnail}
          />
        )}
        <div>
          <p className="font-medium leading-[1.3] mb-1 flex items-center gap-[.3125rem]">
            {label} {Icon && <Icon className="text-white w-[.625rem] h-[.75rem]" />}
          </p>
          {description && (
            <p className="text-xs !leading-[1.3] opacity-[75%] lg:whitespace-pre-line">
              {description}
            </p>
          )}
        </div>
        <span className="bg-neon-green-500 rounded-full rotate-180 w-6 h-6 text-black absolute right-4 lg:right-8 hidden group-hover:inline">
          <ArrowIcon />
        </span>
      </a>
    </li>
  );
};
