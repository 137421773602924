import { JsonObject } from 'type-fest';

export const onboarding: JsonObject = {
  step: 'Krok',
  basicInfoTitleStep: {
    title: 'Podstawowe informacje',
    weWillAskYou: 'W tym kroku chcemy Cię lepiej poznać. Zapytamy Cię o:',
    fillOrConfirmData:
      'Hej, wygląda na to, że trochę się już znamy! W następnych krokach potwierdź lub uzupełnij swoje dane',
    stepContent: {
      personalInformation: 'Twoje imię i nazwisko',
      technologies: 'Twoją rolę i technologie, które znasz',
      languages: 'Języki w jakich się komunikujesz',
    },
    nextButtonText: 'Uzupełnij profil',
  },
  nameStep: {
    title: 'Dane osobowe',
    addYourCv: 'Dodaj swoje CV (opcjonalne)',
    allowedFormats: 'Dozwolone formaty: PDF, DOCX. Maksymalny rozmiar 5 MB',
  },
  roleStep: {
    title: 'Witaj w C_hub, {{firstName}} 👋',
    jobPositionLabel: 'Daj nam znać, czym się zajmujesz',
    jobPositionPlaceholder: 'Wybierz swoją rolę',
    experienceYearsLabel: 'Ile lat doświadczenia posiadasz?',
    selectMainTechnologyLabel: 'Wskaż technologię wiodącą',
    addOtherRole: 'Dodaj inną rolę',
    seniority: {
      label: 'Jakie masz doświadczenie w tej roli?',
      tooltip: 'Sugerowany na podstawie lat doświadczenia',
    },
  },
  skillsStep: {
    title: 'Jakie znasz technologie?',
    subtitle:
      'Wybierz przynajmniej 3 technologie, abyśmy mogli wybrać pasujące do Ciebie oferty. Za pomocą kropek oznacz poziom znajomości każdej technologii.',
    addNewTechnologyLabel: 'Dodaj  technologię',
  },
  superSkillsStep: {
    title: 'Teraz wybierz swoje TOP umiejętności',
    subtitle: 'Wybierz maksimum 5',
  },
  languagesStep: {
    title: 'W jakich językach się komunikujesz?',
    subtitle: 'Za pomocą kropek oznacz poziom znajomości każdego języka',
    addLanguage: 'Dodaj język',
  },
  preferencesTitleStep: {
    title: 'Preferencje',
    subtitle:
      'Podziel się z nami informacjami o Twoich preferencjach - pomoże nam to wybrać odpowiednie dla Ciebie oferty. Zapytamy Cię o:',
    stepContent: {
      desiredLocation: 'Twoją lokalizację i preferowany tryb pracy',
      desiredTechnologies: 'Technologie, z którymi chcesz pracować',
    },
    nextButtonText: 'Kontynuuję uzupełnianie profilu',
  },
  preferencesStep: {
    title: 'Twoje preferencje',
    subtitle: 'Daj nam znać, jakie są Twoje preferencje odnośnie współpracy.',
    locationLabel: 'Jaka jest Twoja lokalizacja?',
    workTypeLabel: 'Jaki tryb pracy Cię interesuje?',
    desiredOfficeLocationLabel: 'Jaką lokalizację biura preferujesz?',
    desiredOfficeLocationSublabel: 'Możesz wskazać więcej niż 1 lokalizację',
  },
  salaryStep: {
    title: 'Preferowana stawka',
    subtitle: 'Podaj godzinową stawkę netto przy kontrakcie B2B',
    onlyB2bText: 'Obecnie możliwe jest określenie stawki tylko dla kontraktu B2B',
  },
  availabilityStep: {
    title: 'Jaka jest Twoja dostępność?',
    subtitle: 'Od kiedy możesz zacząć nową współpracę?',
    noticePeriod: 'Obowiązuje mnie okres wypowiedzenia',
  },
  expectationsStep: {
    title: 'Oczekiwania',
    subtitle: 'Daj znam znać, czego oczekujesz od nowego projektu',
    desiredTechnicalSkillsLabel: 'Na pracy z jakimi technologiami najbardziej Ci zależy?',
    desiredIndustriesLabel: 'Praca w jakiej branży najbardziej Cię interesuje?',
    desiredIndustriesSublabel: 'Wybierz wszystkie, które Cię interesują',
    desiredIndustriesPlaceholder: 'Wybierz branżę',
    addOtherTechnology: 'Dodaj inne technologie',
  },
  experienceTitleStep: {
    title: 'Twoje doświadczenie',
    subtitle:
      'W tym kroku chcielibyśmy poznać Twoje doświadczenie zawodowe, dlatego poprosimy Cię od podanie:',
    stepContent: {
      yourExperience: 'Informacji o Twoim dotychczasowym doświadczeniu',
      technologies: 'Technologii, które wykorzystywałeś/aś',
      certificates: 'Certyfikatów lub kursów, które zdobyłeś/aś',
    },
    optionalInfo:
      'Te kroki są nieobowiązkowe, ale im więcej informacji podasz, tym lepiej dopasujemy rekomendowane wyniki',
    continueBtnText: 'Kontynuuję uzupełnianie profilu',
    skipBtnText: 'Na razie pominę ten krok',
  },
  experienceStep: {
    title: 'Jakie masz doświadczenie?',
    addAnotherExperience: 'Dodaj kolejne doświadczene',
    editModalTitle: 'Edycja doświadczenia',
    removeExperienceConfirmText: 'Czy na pewno chcesz usunąć to doświadczenie?',
    hideCompanyName: 'Nie chcę podawać nazwy firmy',
  },
  certificatesStep: {
    title: 'Kursy i certyfikaty',
    addAnotherCertificate: 'Dodaj kolejny kurs lub certyfikat',
    editModalTitle: 'Edycja kursu lub certifikatu',
    removeCertificateConfirmText: 'Czy na pewno chcesz usunąć ten kurs lub certyfikat?',
  },
  previewStep: {
    title: 'Podgląd profilu',
    subtitle: 'Oto Twój profil widoczny dla zespołu Connectis. Sprawdź, czy dane są poprawne.',
    openAtFullScreen: 'Wyświetl na pełnym ekranie',
  },
  offersLoading: {
    title: 'Trwa przygotowanie ofert',
    subtitle:
      'Na podstawie Twoich informacji przeszukujemy naszą bazę w poszukiwaniu najlepiej dopasowanych ofert.',
  },
};
