import aboutUsSrc from 'assets/images/job-board/navbar/about-us.webp';
import generativeAISrc from 'assets/images/job-board/navbar/ai.png';
import becomeConnectedExpertSrc from 'assets/images/job-board/navbar/become-expert.webp';
import caseStudiesSrc from 'assets/images/job-board/navbar/case-studies.webp';
import cloudServicesSrc from 'assets/images/job-board/navbar/cloud.png';
import cPlatformSrc from 'assets/images/job-board/navbar/c_platform.avif';
import hireAnExpertSrc from 'assets/images/job-board/navbar/hire-expert.webp';
import jobOffersSrc from 'assets/images/job-board/navbar/job-orders.webp';
import dataAndMLAISrc from 'assets/images/job-board/navbar/ml.png';
import applicationEngineeringServicesSrc from 'assets/images/job-board/navbar/projecting.png';
import ourServicesSrc from 'assets/images/job-board/navbar/services.webp';
import techExpertsSrc from 'assets/images/job-board/navbar/tech-experts.webp';
import digitalTransformationSrc from 'assets/images/job-board/projects/digitalTransformation.avif';
import energySrc from 'assets/images/job-board/projects/energy.avif';
import financesSrc from 'assets/images/job-board/projects/finances.avif';
import retailSrc from 'assets/images/job-board/projects/retail.avif';
import { IconType, RedirectIcon } from 'icons';
import { EnvironmentConfig } from '../../../environment-config';

export interface ConnectisWebsiteMenuItem {
  name: string;
  thumbnail?: string;
  url: string;
  header?: boolean;
  menuOrder?: number;
  icon?: IconType;
}

export interface ConnectisWebsiteMenu {
  name: string;
  items: ConnectisWebsiteMenuItem[];
  portfolioProject?: ConnectisWebsiteMenuItem;
}

export const menuSchema: ConnectisWebsiteMenu[] = [
  {
    name: 'ourServices',
    items: [
      {
        name: 'hireAnExpert',
        url: `${EnvironmentConfig.connectisWebsiteUrl}/zatrudnij-eksperta`,
        header: false,
      },
      {
        name: 'generativeAI',
        thumbnail: generativeAISrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/uslugi/generative-ai`,
        menuOrder: 1,
      },
      {
        name: 'dataAndMLAI',
        thumbnail: dataAndMLAISrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/uslugi/data-ml-ai`,
        menuOrder: 3,
      },
      {
        name: 'applicationEngineeringServices',
        thumbnail: applicationEngineeringServicesSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/uslugi/rozwoj-oprogramowania`,
        menuOrder: 4,
      },
      {
        name: 'cloudServices',
        thumbnail: cloudServicesSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/uslugi/rozwiazania-chmurowe`,
        menuOrder: 2,
      },
    ],
    portfolioProject: {
      name: 'digitalTransformation',
      thumbnail: digitalTransformationSrc,
      url: `${EnvironmentConfig.connectisWebsiteUrl}/works/dedykowany-system-wspierajacy-cyfrowa-transformacje`,
    },
  },
  {
    name: 'companies',
    items: [
      {
        name: 'hireAnExpert',
        thumbnail: hireAnExpertSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/zatrudnij-eksperta`,
      },
      {
        name: 'ourServices',
        thumbnail: ourServicesSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/uslugi`,
      },
      {
        name: 'iNeedAQuote',
        url: `${EnvironmentConfig.connectisWebsiteUrl}/formularz`,
        header: false,
      },
    ],
    portfolioProject: {
      name: 'energy',
      thumbnail: energySrc,
      url: `${EnvironmentConfig.connectisWebsiteUrl}/works/outsourcing-it-dla-koncernu-energetycznego`,
    },
  },
  {
    name: 'experts',
    items: [
      {
        name: 'techExperts',
        thumbnail: techExpertsSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/dla-ekspertow`,
      },
      {
        name: 'becomeConnectedExpert',
        thumbnail: becomeConnectedExpertSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/connected-experts`,
      },
      {
        name: 'jobOffers',
        thumbnail: jobOffersSrc,
        url: 'https://hub.connectis.pl/job-board',
      },
      {
        name: 'cHub',
        thumbnail: cPlatformSrc,
        icon: RedirectIcon,
        url: 'https://hub.connectis.pl/',
      },
    ],
    portfolioProject: {
      name: 'retail',
      thumbnail: retailSrc,
      url: `${EnvironmentConfig.connectisWebsiteUrl}/works/outsourcing-it-dla-lidera-branzy-retail`,
    },
  },
  {
    name: 'whyConnectis',
    items: [
      {
        name: 'caseStudies',
        thumbnail: caseStudiesSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/case-studies`,
      },
      {
        name: 'aboutUs',
        thumbnail: aboutUsSrc,
        url: `${EnvironmentConfig.connectisWebsiteUrl}/o-nas`,
      },
      {
        name: 'ambassadorProgram',
        url: `${EnvironmentConfig.connectisWebsiteUrl}/program-ambasadorski`,
        header: false,
      },
      {
        name: 'contact',
        url: `${EnvironmentConfig.connectisWebsiteUrl}/kontakt`,
        header: false,
      },
    ],
    portfolioProject: {
      name: 'finances',
      thumbnail: financesSrc,
      url: `${EnvironmentConfig.connectisWebsiteUrl}/works/outsourcing-it-dla-lidera-bankowosci-cyfrowej`,
    },
  },
];
export const getStartedUrl = `${EnvironmentConfig.connectisWebsiteUrl}/kontakt#getstarted`;
