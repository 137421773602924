import { JsonObject } from 'type-fest';

export const datePicker: JsonObject = {
  selectedDates: 'Wybrane daty',
  selectedDaysAndTimeslots: 'Wybrane daty i godziny',
  meetingLength: '-godzinne spotkanie',
  meetingDuration: 'Czas spotkania: ',
  oneHour: '1 godzina',
  today: 'Dzisiaj',
  weekDaysShort: {
    monday: 'Pon',
    tuesday: 'Wt',
    wednesday: 'Śr',
    thursday: 'Czw',
    friday: 'Pt',
    saturday: 'So',
    sunday: 'Nd',
  },
  scheduleMeetingModal: {
    title: 'Podaj swoją dostępność, aby aplikować i umówić spotkanie',
    subtitle:
      'Na początek sprawdzimy Twój profil, a następnie umówimy na spotkanie wstępne, aby trochę lepiej Cię poznać.',
    availableDays: 'Dni dostępne, aby umówić spotkanie',
    unavailableDays: 'Dni niedostępne, aby umówić spotkanie',
    actions: {
      send: 'Wyślij',
      confirm: 'Potwierdź',
    },
  },
  meetingHourSelect: {
    placeholder: {
      title: 'Wybierz datę i godzinę',
      subtitle: 'Możesz wybrać maksymalnie 5 dni, a do każdego dnia godziny jakie Ci odpowiadają.',
    },
    addTimeslot: 'Wybierz godzinę',
    addTimeslots: 'Wybierz godziny',
    addAnotherTime: 'Dodaj kolejną godzinę',
    addAnotherDate: 'Dodaj kolejny dzień',
    addTimeOfMeeting: 'Wybierz godzinę spotkania',
  },
  successBox: {
    title: 'Dziękujemy',
    subtitle: 'Jesteśmy w trakcie weryfikacji Twojego profilu.',
    verificationInfo:
      'Po pomyślnej weryfikacji potwierdzimy termin spotkania i wyślemy na Twój adres \n e-mail niezbędne informacje.',
    contactPerson: 'Osoba kontaktowa',
    selectedTimeslots: 'Wybrane terminy',
    close: 'Zamknij',
    next: 'Dalej',
  },
  workHistoryBox: {
    title: 'Uzupełnij historię pracy',
    subtitle:
      'Doświadczenie zawodowe to istotny element, który przyciąga uwagę rekruterów. \n Czy chcesz uzupełnić historię pracy? Zwiększy to szanse na lepszą weryfikację profilu.',
    fill: 'Uzupełniam',
    noFill: 'Nie uzupełniam',
  },
  errorMessage:
    'Coś poszło nie tak, prosimy spróbować później lub skontaktować się z administratorem',
  noDaysPickedError: 'Wybierz dzień lub dni, a następnie przejdź do wyboru godzin spotkania',
  firstMeetingBox: {
    title: 'To Twoje pierwsze spotkanie!',
    description:
      'Na początek umówimy Cię na spotkanie online ze Specjalistą Connectis, aby trochę lepiej Cię poznać.',
  },
  timeslotsLimitExceeded: 'Możesz wybrać maksymalnie 5 dni.',
};
