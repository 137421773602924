import { StatusBadge } from 'components';
import { TFunction } from 'i18next';
import { JobOrderApplicationProcessStatusEnum } from 'interfaces';

const recruitmentInProgressStatuses = [
  JobOrderApplicationProcessStatusEnum.AcceptedAfterCq,
  JobOrderApplicationProcessStatusEnum.Sent,
  JobOrderApplicationProcessStatusEnum.NotSent,
  JobOrderApplicationProcessStatusEnum.MeetingsScheduled,
];

export const recruitmentInterviewStatuses = [
  JobOrderApplicationProcessStatusEnum.CiScheduled,
  JobOrderApplicationProcessStatusEnum.CiScheduledSecondStage,
  JobOrderApplicationProcessStatusEnum.CiScheduledThirdStage,
];

const decisionIsCloseStatuses = [
  JobOrderApplicationProcessStatusEnum.CiFinished,
  JobOrderApplicationProcessStatusEnum.CiFinishedSecondStage,
  JobOrderApplicationProcessStatusEnum.CiFinishedThirdStage,
];

const rejectedStatuses = [
  JobOrderApplicationProcessStatusEnum.Rejected,
  JobOrderApplicationProcessStatusEnum.RejectedAfterCq,
  JobOrderApplicationProcessStatusEnum.RejectedAfterApplied,
  JobOrderApplicationProcessStatusEnum.RejectedBySales,
  JobOrderApplicationProcessStatusEnum.NoFeedback,
  JobOrderApplicationProcessStatusEnum.CiCancelledByClient,
  JobOrderApplicationProcessStatusEnum.CiCancelledByExpert,
  JobOrderApplicationProcessStatusEnum.RejectedAfterCi,
  JobOrderApplicationProcessStatusEnum.ExpertWithdrew,
  JobOrderApplicationProcessStatusEnum.ClientWithdrew,
  JobOrderApplicationProcessStatusEnum.RejectedOffer,
  JobOrderApplicationProcessStatusEnum.RejectedByExpert,
];

interface GetStatusBadgeProps {
  processStatus?: JobOrderApplicationProcessStatusEnum;
}

export const getStatusBadge = (t: TFunction, { processStatus }: GetStatusBadgeProps) => {
  if (!processStatus) {
    return;
  }

  if (recruitmentInProgressStatuses.includes(processStatus)) {
    return <StatusBadge label={t('offers:processStatus.acceptedAfterCq')} variant="default" />;
  }

  if (processStatus === JobOrderApplicationProcessStatusEnum.Applied) {
    return <StatusBadge label={t('offers:processStatus.applied')} variant="default" />;
  }

  if (processStatus === JobOrderApplicationProcessStatusEnum.ScheduledForCq) {
    return <StatusBadge label={t('offers:processStatus.scheduledForCq')} variant="primary" />;
  }

  if (processStatus === JobOrderApplicationProcessStatusEnum.OfferExpired) {
    return <StatusBadge label={t('offers:processStatus.offerExpired')} variant="offerExpired" />;
  }

  if (recruitmentInterviewStatuses.includes(processStatus)) {
    return <StatusBadge label={t('offers:processStatus.appointmentForCi')} variant="primary" />;
  }

  if (decisionIsCloseStatuses.includes(processStatus)) {
    return <StatusBadge label={t('offers:processStatus.ciPerformed')} variant="primary" />;
  }

  if (processStatus === JobOrderApplicationProcessStatusEnum.Accepted) {
    return <StatusBadge label={t('offers:processStatus.offering')} variant="success" />;
  }

  if (processStatus === JobOrderApplicationProcessStatusEnum.Placement) {
    return <StatusBadge label={t('offers:processStatus.placement')} variant="success" />;
  }

  if (rejectedStatuses.includes(processStatus)) {
    return <StatusBadge label={t('offers:processStatus.rejected')} variant="secondary" />;
  }
};
