import { useReactiveVar } from '@apollo/client';
import { useFeatureFlag, useSitemap } from 'hooks';
import {
  ExpertOfferSuccessPage,
  ExternalOfferPage,
  JobBoardOfferPage,
  JobBoardPage,
  OffersPage,
  OnboardingPage,
  ProfilePage,
  SettingsPage,
  SignInPage,
  SignUpPage,
} from 'pages';
import { useState } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { profileVar } from 'reactive-vars';
import { MyApplicationsPage } from '../MyApplications';
import NotificationsPage from '../Notifications/NotificationsPage';
import { ConditionalRedirectRoute } from './subcomponents';

export const AuthenticatedRoutes = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const profile = useReactiveVar(profileVar);
  const sitemap = useSitemap();
  const isJobBoardDisabled = useFeatureFlag('jobBoard');

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  if (!profile) {
    return null;
  }

  return (
    <Routes>
      {!isJobBoardDisabled && (
        <Route path="/job-board">
          <Route element={<JobBoardPage />} index />
          <Route element={<JobBoardOfferPage />} path=":offerId" />
        </Route>
      )}
      <Route
        element={
          <ConditionalRedirectRoute
            condition={() => (profile as any).isOnboardingFinished || false}
            keepPrevPath
            keepSearchParams
            redirectPath={sitemap.expertOnboarding()}
          />
        }
      >
        <Route element={<Navigate to={sitemap.offers()} />} path="/" />
        <Route path="/profile">
          <Route
            element={
              <ProfilePage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            index
          />
          <Route
            element={
              <ProfilePage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            path=":tabName"
          />
        </Route>
        <Route path="/settings">
          <Route
            element={
              <SettingsPage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            index
          />
          <Route
            element={
              <SettingsPage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            path=":tabName"
          />
        </Route>
        <Route path="/offers">
          <Route
            element={
              <OffersPage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            index
          />
          <Route
            element={
              <OffersPage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            path=":tabName"
          />
        </Route>
        <Route path="/applications">
          <Route
            element={
              <MyApplicationsPage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            index
          />
          <Route
            element={
              <MyApplicationsPage
                isMobileMenuOpen={isMobileMenuOpen}
                onMobileMenuToggle={handleMobileMenuToggle}
              />
            }
            path=":tabName"
          />
        </Route>
      </Route>
      <Route path="/onboarding">
        <Route element={<OnboardingPage />} index />
        <Route element={<OnboardingPage />} path=":stepName" />
      </Route>
      <Route
        element={
          <NotificationsPage
            isMobileMenuOpen={isMobileMenuOpen}
            onMobileMenuToggle={handleMobileMenuToggle}
          />
        }
        path="/notifications"
      />
      <Route path="/offer">
        <Route element={<ExternalOfferPage />} path=":offerId" />
        <Route element={<ExpertOfferSuccessPage />} path=":offerId/success" />
      </Route>
      <Route element={<Navigate to={sitemap.offers()} />} path="*" />
    </Routes>
  );
};

export const DefaultRoutes = () => {
  const sitemap = useSitemap();
  const [params] = useSearchParams();
  const { pathname } = useLocation();
  const isJobBoardDisabled = useFeatureFlag('jobBoard');

  if (pathname) {
    params.append('pageRedirect', pathname);
  }
  const paramsString = params.toString();

  return (
    <Routes>
      <Route element={<SignInPage />} index />
      <Route element={<SignUpPage />} path="/sign-up" />
      <Route element={<ExternalOfferPage />} path="/offer">
        <Route element={<ExternalOfferPage />} index />
        <Route element={<ExternalOfferPage />} path=":offerId" />
      </Route>
      {!isJobBoardDisabled && (
        <Route path="/job-board">
          <Route element={<JobBoardPage />} index />
          <Route element={<JobBoardOfferPage />} path=":offerId" />
        </Route>
      )}
      <Route
        element={<Navigate to={`${sitemap.main}${paramsString ? `?${paramsString}` : ''}`} />}
        path="*"
      />
    </Routes>
  );
};
