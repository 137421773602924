import { JsonObject } from 'type-fest';

export const connectisWebsite: JsonObject = {
  hero: {
    jobOffers: 'Oferty pracy',
    title: 'Dołącz do nas',
    subtitle: 'Wybierz projekt dopasowany do Twoich potrzeb.',
    trustedUs: 'Zaufali nam',
    ourPortfolio: 'Nasze portfolio',
  },
  hubSection: {
    title: 'Aplikuj z C_hub',
    description:
      'C_hub to platforma, która dopasuje oferty pracy na podstawie Twojego doświadczenia i preferencji.\n Przyspiesz proces rekrutacji i umów się na spotkanie!',
    joinHub: 'Dołącz do C_hub',
    startWithHub: 'Tutaj zaczynasz z C_hub',
    step: 'Krok',
    steps: [
      'Znalezienie oferty',
      'Przesłanie zgłoszenia',
      'Analiza zgłoszenia',
      'Rozmowa z Connectis',
      'Rozmowa techniczna',
      'Zatrudnienie',
    ],
  },
  menus: {
    ourServices: {
      heading: 'Nasze usługi',
      headingFooter: 'Oferta',
      items: {
        generativeAI: {
          label: 'Generative AI',
          description:
            'Przyspiesz obniżenie kosztów, zwiększenie efektywności i tworzenie nowych produktów.',
        },
        dataAndMLAI: {
          label: 'Data & ML / AI',
          description: 'Zmień swoje dane w przewagę konkurencyjną.',
        },
        applicationEngineeringServices: {
          label: 'Rozwój oprogramowania',
          description:
            'Skróć czas realizacji projektów technologicznych i szybciej osiągaj swoje cele biznesowe.',
        },
        cloudServices: {
          label: 'Rozwiązania chmurowe',
          description: 'Przyspiesz wdrażanie innowacyjnych rozwiązań w\xa0chmurze.',
        },
        hireAnExpert: {
          label: 'Zatrudnij eksperta',
        },
      },
    },
    companies: {
      heading: 'Dla firm',
      items: {
        hireAnExpert: {
          description:
            'Przyśpiesz rekrutację i jej skuteczność dzięki metodzie 10 punktów dopasowania™.',
          label: 'Zatrudnij eksperta',
        },
        ourServices: {
          description: 'Zamów usługę kompleksowego wykonania projektu lub jego części.',
          label: 'Nasze usługi',
        },
        iNeedAQuote: {
          label: 'Umów spotkanie',
        },
      },
    },
    experts: {
      heading: 'Dla Ekspertów',
      items: {
        techExperts: {
          description: 'Sprawdź dlaczego warto rozwijać swoją\n karierę z Connectis_®',
          label: 'Dla Ekspertów IT',
          footerLabel: 'Dla Tech Ekspertów',
        },
        becomeConnectedExpert: {
          description: 'Dołącz do naszej społeczności najlepszych ekspertów i ekspertek IT.',
          label: 'Zostań Connected Expert™',
        },
        jobOffers: {
          description: 'Znajdź najlepiej wpisujący się w Twoje potrzeby.',
          label: 'Oferty Projektów',
        },
        cHub: {
          label: 'Platforma Connectis',
          description:
            'Utwórz profil i otrzymuj automatyczne powiadomienia o dopasowanych projektach.',
        },
        referralProgram: {
          label: 'Program poleceń',
        },
      },
    },
    whyConnectis: {
      heading: 'Dlaczego Connectis',
      headingFooter: 'Dlaczego Connectis_',
      items: {
        caseStudies: {
          description: 'Wybrane projekty będące efektem pracy naszych ekspertek i ekspertów IT.',
          label: 'Case studies',
        },
        aboutUs: {
          description:
            'Doświadczeni i starannie wyselekcjonowani eksperci IT oraz najnowsze technologie.',
          label: 'O nas',
        },
        ambassadorProgram: {
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus in turpis rhoncus sem maximus feugiat id non orci.',
          label: 'Program ambasadorski',
        },
        contact: {
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          label: 'Kontakt',
        },
      },
    },
  },
  signInButton: {
    signIn: 'Zaloguj się',
    login: 'Logowanie',
    iAmExpert: 'Jestem ekspertem',
    iAmClient: 'Jestem klientem',
  },
  headerMenu: {
    jobOrders: 'Oferty pracy',
    getStarted: 'Get Started',
  },
  portfolioProject: {
    seeProject: 'Zobacz projekt',
    digitalTransformation: {
      title: 'Transformacja cyfrowa',
      description:
        'Wsparcie dedykowanym rozwiązaniem usprawniającym procesy i poprawiającym konkurencyjność',
    },
    energy: {
      title: 'Outsourcing IT - energetyka',
      description: 'Realizacja kompleksowych projektów z obszaru redukcji CO2 i rozwoju OZE',
    },
    retail: {
      title: 'Outsourcing IT - retail',
      description:
        'Ograniczenie rotacji i poprawa efektywności poprzez weryfikację technologiczną i dopasowania do kultury organizacyjnej',
    },
    finances: {
      title: 'Outsourcing IT - finanse',
      description:
        'Współtworzenie strategii rozwoju obszaru Outsourcingu IT oraz wsparcie implementacyjne',
    },
  },
  bottomMenu: {
    officeConnectis: 'office.pl@connectis.pl',
    privacy: 'Polityka prywatności',
    sitemap: 'Sitemap',
    termsOfService: 'Terms of service',
  },
};
